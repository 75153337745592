import React from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import "bootstrap/dist/css/bootstrap.min.css";
import SRC_Logo from "./Logo55x55.png";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import Upload from "./components/Upload";
import Guidelines from "./components/Guidelines";
import Assets from "./components/Assets";

const App = () => {
  return (
    <BrowserRouter>
      <Navbar bg="dark" variant="dark">
        <Container>
          <Link className="navbar-brand" to="/">
            <img
              src={SRC_Logo}
              width="30"
              height="30"
              className="d-inline-block align-top"
              alt="React Bootstrap logo"
            />{" "}
            Valorant Lineups
          </Link>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Link className="nav-link" to="/">
                Upload
              </Link>
              <NavDropdown title="Help" id="basic-nav-dropdown">
                <Link className="dropdown-item" to="/guidelines">
                  Contribution guidelines
                </Link>
                <Link className="dropdown-item" to="/assets">
                  Video assets
                </Link>
              </NavDropdown>
              <a
                href="https://discord.gg/RARcPbVApn"
                target="_blank"
                className="nav-link"
                without
                rel="noreferrer"
              >
                Join or Discord server
              </a>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container className="mt-4">
        <Routes>
          <Route path="/" element={<Upload />} />
          <Route path="/guidelines" element={<Guidelines />} />
          <Route path="/assets" element={<Assets />} />
        </Routes>
      </Container>
    </BrowserRouter>
  );
};

export default App;
