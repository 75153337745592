import CONFIG from "./config/config.json";

const fetchDefaults = (map, agent, ability) =>
  new Promise((resolve, reject) => {
    fetch(`${CONFIG.API_URL}/get/form`)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        resolve();
        return;
      })
      .then((result) => {
        resolve(result);
      });
  });

const uploadVideo = (body) =>
  new Promise((resolve, reject) => {
    fetch(`${CONFIG.API_URL}/injest/publish`, {
      method: "POST",
      body,
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        resolve();
        return;
      })
      .then((result) => {
        resolve(result);
      });
  });

export { fetchDefaults, uploadVideo };
