import React, { useEffect, useState, useCallback, useRef } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Row from "react-bootstrap/Row";
import { fetchDefaults, uploadVideo } from "../utils";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
} from "react-simple-captcha";
import { Link } from "react-router-dom";

const Upload = () => {
  const [isLoadingDefaults, setIsLoadingDefaults] = useState(true);
  const [abilities, setAbilities] = useState([]);
  const [agents, setAgents] = useState([]);
  const [free, setFree] = useState([]);
  const [maps, setMaps] = useState([]);
  const [positions, setPositions] = useState([]);
  const [sides, setSides] = useState([]);
  const [isGuidlineRead, setGuidlineRead] = useState(false);
  const fileRef = useRef();
  const [authors] = useState([
    {
      id: "guest",
      value: "Guest",
    },
    {
      id: "partner",
      value: "Partner ID",
    },
  ]);
  //Form states
  const [map, setMap] = useState();
  const [agent, setAgent] = useState();
  const [ability, setAbility] = useState();
  const [side, setSide] = useState();
  const [position, setPosition] = useState();
  const [isFree, setIsFree] = useState();
  const [title, setTitle] = useState("");
  const [author, setAuthor] = useState(authors[0].id);
  const [pid, setPid] = useState("");
  const [saveResult, setSaveResult] = useState(null);
  const [saveMessage, setSaveMessage] = useState("");
  const [captcha, setCaptcha] = useState("");
  const [isSaving, setIsSaving] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchDefaults();
      setIsLoadingDefaults(false);
      setTimeout(() => {
        setGuidlineRead(false);
      }, 3000);
      if (data != null) {
        if (data.abilities != null && data.abilities.length > 0) {
          setAbilities(data.abilities);
          setAbility(data.abilities[0].id);
        }
        if (data.agents != null && data.agents.length > 0) {
          setAgents(data.agents);
          setAgent(data.agents[0].id);
        }
        if (data.free != null && data.free.length > 0) {
          setFree(data.free);
          setIsFree(data.free[0].id);
        }
        if (data.maps != null && data.maps.length > 0) {
          setMaps(data.maps);
          setMap(data.maps[0].id);
        }
        if (data.positions != null && data.positions.length > 0) {
          setPositions(data.positions);
          setPosition(data.positions[0].id);
        }
        if (data.sides != null && data.sides.length > 0) {
          setSides(data.sides);
          setSide(data.sides[0].id);
        }
      }
    };
    setIsLoadingDefaults(true);
    fetchData();
    loadCaptchaEnginge(4);
  }, [
    setIsLoadingDefaults,
    setAbilities,
    setAbility,
    setAgents,
    setAgent,
    setFree,
    setMaps,
    setMap,
    setPositions,
    setSides,
    setSide,
    setPosition,
  ]);

  const [validated, setValidated] = useState(false);

  const successfulUpload = useCallback(
    (resolve) => {
      setSaveResult(true);
      setSaveMessage(resolve.message);
      setTitle("");
      setCaptcha("");
      setValidated(false);
      loadCaptchaEnginge(4);
      setGuidlineRead(false);
      setIsSaving(false);
    },
    [
      setSaveResult,
      setSaveMessage,
      setTitle,
      setCaptcha,
      setValidated,
      setGuidlineRead,
      setIsSaving,
    ]
  );
  const failedUpload = useCallback(
    (message) => {
      setSaveResult(false);
      setSaveMessage(message);
      setIsSaving(false);
    },
    [setSaveResult, setSaveMessage, setIsSaving]
  );

  const handleSubmit = useCallback(
    (event) => {
      const form = event.currentTarget;
      setValidated(true);
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      } else {
        event.preventDefault();
        event.stopPropagation();
        if (validateCaptcha(captcha, false) === false) {
          setSaveResult(false);
          setSaveMessage("Invalid captcha.");
          return;
        } else if (fileRef.current.files.length > 0) {
          setIsSaving(true);
          const data = {
            map,
            agent,
            ability,
            side,
            position,
            free: isFree,
            title,
            author,
            pid: pid != null && pid.length > 0 ? pid : null,
          };
          const fileData = new FormData();
          Object.keys(data).forEach((key) => {
            fileData.append(key, data[key]);
          });
          fileData.append("media", fileRef.current.files[0]);
          uploadVideo(fileData).then((resolve) => {
            if (resolve != null && resolve.success === true) {
              successfulUpload(resolve);
            } else if (resolve != null) {
              failedUpload(resolve.message);
            } else {
              failedUpload(
                "Unknown error happened, try again. If you see this message again, contact us."
              );
            }
          });
        }
      }
    },
    [
      setValidated,
      map,
      agent,
      ability,
      side,
      position,
      isFree,
      title,
      author,
      pid,
      fileRef,
      captcha,
      setSaveResult,
      setSaveMessage,
      setIsSaving,
      successfulUpload,
      failedUpload,
    ]
  );

  const isLoading = isLoadingDefaults || isSaving;

  return (
    <Row>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        {/*   <Alert variant="warning">
          Website temporarily not functioning as we are migrating new features.
          Will be available in few days. If you have any questions reach out on
          discord.
        </Alert> */}
        <Form.Group className="mb-3" controlId="form.map">
          <Form.Label>Map</Form.Label>
          <Form.Select
            required
            value={map}
            onChange={(e) => setMap(e.target.value)}
          >
            {maps.map(({ id, value }) => (
              <option key={`map-${id}`} value={id}>
                {value}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-3" controlId="form.agent">
          <Form.Label>Agent</Form.Label>
          <Form.Select
            required
            value={agent}
            onChange={(e) => setAgent(e.target.value)}
          >
            {agents.map(({ id, value }) => (
              <option key={`agents-${id}`} value={id}>
                {value}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-3" controlId="form.ability">
          <Form.Label>Ability</Form.Label>
          <Form.Select
            required
            value={ability}
            onChange={(e) => setAbility(e.target.value)}
          >
            {abilities.map(({ id, value }) => (
              <option key={`ability-${id}`} value={id}>
                {value}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-3" controlId="form.side">
          <Form.Label>Side</Form.Label>
          <Form.Select
            required
            value={side}
            onChange={(e) => setSide(e.target.value)}
          >
            {sides.map(({ id, value }) => (
              <option key={`side-${id}`} value={id}>
                {value}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-3" controlId="form.position">
          <Form.Label>Position</Form.Label>
          <Form.Select
            required
            value={position}
            onChange={(e) => setPosition(e.target.value)}
          >
            {positions.map(({ id, value }) => (
              <option key={`pos-${id}`} value={id}>
                {value}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-3" controlId="form.free">
          <Form.Label>Is Free</Form.Label>
          <Form.Select
            required
            value={isFree}
            onChange={(e) => setIsFree(e.target.value)}
          >
            {free.map(({ id, value }) => (
              <option key={`free-${id}`} value={id}>
                {value}
              </option>
            ))}
          </Form.Select>
          <Form.Text className="text-muted">
            Whether the video is only visible for users who have active
            subscription.
          </Form.Text>
        </Form.Group>
        <Form.Group className="mb-3" controlId="form.free">
          <Form.Label>Title</Form.Label>
          <Form.Control
            type="text"
            required
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Short video title"
            minLength="5"
            maxLength="80"
          />
          <Form.Control.Feedback type="invalid">
            Requirements: minimum 5 character, maximum 80.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3" controlId="form.video">
          <Form.Label>Video file</Form.Label>
          <Form.Control
            required
            type="file"
            accept="video/mp4"
            name="media"
            ref={fileRef}
          />
          <Form.Text className="text-muted">
            H264, MP4, 1920x1080, 60FPS, less then 30MB, less then 25 seconds,
            no audio attached.
          </Form.Text>
          <Form.Control.Feedback type="invalid">
            Requirements: H264, MP4, 1920x1080, 60FPS, less then 30MB, less then
            25 seconds, no audio attached.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3" controlId="form.author">
          <Form.Label>Creator</Form.Label>
          <Form.Select
            required
            value={author}
            onChange={(e) => setAuthor(e.target.value)}
          >
            {authors.map(({ id, value }) => (
              <option key={`author-${id}`} value={id}>
                {value}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        {author === "partner" && (
          <Form.Group className="mb-3" controlId="form.pid">
            <Form.Label>Partner ID</Form.Label>
            <Form.Control
              type="text"
              required
              placeholder="Partner identification number"
              minLength="36"
              maxLength="36"
              value={pid}
              onChange={(e) => setPid(e.target.value)}
            />
            <Form.Text className="text-muted">
              Invalid partner identifcation number will be considered as guest
              contribution.
            </Form.Text>
            <Form.Control.Feedback type="invalid">
              Requirements: exactly 36 characters.
            </Form.Control.Feedback>
          </Form.Group>
        )}

        <Form.Group className="mb-3" controlId="form.captcha">
          <Form.Label>Captcha</Form.Label>
          <Form.Control
            type="text"
            required
            value={captcha}
            onChange={(e) => setCaptcha(e.target.value)}
            placeholder="Captcha value"
            minLength="4"
            maxLength="4"
          />
          <div className="mt-3">
            <LoadCanvasTemplate />
          </div>
        </Form.Group>
        <Form.Group className="mb-3" controlId="form.check">
          <Form.Check
            required
            checked={isGuidlineRead}
            onChange={(e) => setGuidlineRead(e.target.checked)}
            type="checkbox"
            label="I have read and understood contribution guidelines."
          />
          <Link to="guidelines">Contribution guidelines</Link>
        </Form.Group>
        {saveResult != null && (
          <Row>
            <div className="d-grid gap-2">
              <Alert variant={saveResult ? "success" : "danger"}>
                {saveMessage}
              </Alert>
            </div>
          </Row>
        )}
        <div className="d-grid gap-2 mt-3 mb-2">
          <Button
            disabled={isLoading}
            variant="primary"
            type="submit"
            size="lg"
          >
            {isSaving ? "Uploading  in progress..." : "Upload"}
          </Button>
        </div>
      </Form>
    </Row>
  );
};

export default Upload;
//Contribution check control, reset on successfull
//Testing upload
//Guidlines
//Assets
