import React from "react";
import Row from "react-bootstrap/Row";
import Badge from "react-bootstrap/Badge";

const Guidelines = () => {
  return (
    <Row>
      <h5>
        Contributing Guidlines <Badge bg="secondary">Important</Badge>
      </h5>
      <p>
        By contributing you declare that you had all the copyrights to the
        content uploaded. If you are not sure, please do not ever use the
        platform.
      </p>
      <p>
        By contributing any content on our platform you hereby grant all the
        rights, free of charge to us. When you grant all the rights to us, it
        means that the content uploaded on this platform could be used by
        Valorant Lineups team without any restriction or limitation. We can
        modify it, merge it, copy it, publish it, distribute it, sell it or
        sublicense it.
      </p>
      <p>
        Our partner contributors could request us to display their nickname
        linking to their social media account in our platform next to the
        content they uploaded.
      </p>
      <p>
        Every video goes trough a review process. This usually takes less then
        24 hours.
      </p>
      <p>We appriciate your contributions - Valornat Lineups team 01/17/2022</p>
    </Row>
  );
};

export default Guidelines;
