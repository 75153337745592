import React from "react";
import Container from "react-bootstrap/Row";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Badge from "react-bootstrap/Badge";
import Image from "react-bootstrap/Image";
import SRC_SovaBounce0 from "../assets/sova_bounce_0.png";
import SRC_SovaBounce1 from "../assets/sova_bounce_1.png";
import SRC_SovaBounce2 from "../assets/sova_bounce_2.png";
import SRC_SovaCharge0 from "../assets/sova_charge_0.png";
import SRC_SovaCharge1 from "../assets/sova_charge_1.png";
import SRC_SovaCharge2 from "../assets/sova_charge_2.png";
import SRC_SovaCharge3 from "../assets/sova_charge_3.png";

const Assets = () => {
  return (
    <Container>
      <Row>
        <Col>
          <h5>
            Assets <Badge bg="secondary">Important</Badge>
          </h5>
          <p>
            Assets on this page are encouraged to be used while creating
            content.
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>Please use these assets for Sova videos.</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <h6>Sova - No bounce</h6>
          <p>
            <Image src={SRC_SovaBounce0} />
          </p>
          <h6>Sova - 1 bounce</h6>
          <p>
            <Image src={SRC_SovaBounce1} />
          </p>
          <h6>Sova - 2 bounce</h6>
          <p>
            <Image src={SRC_SovaBounce2} />
          </p>
        </Col>
        <Col>
          <h6>Sova - No charge</h6>
          <p>
            <Image src={SRC_SovaCharge0} />
          </p>
          <h6>Sova - 1 charge</h6>
          <p>
            <Image src={SRC_SovaCharge1} />
          </p>
          <h6>Sova - 2 charge</h6>
          <p>
            <Image src={SRC_SovaCharge2} />
          </p>
          <h6>Sova - 3 charge</h6>
          <p>
            <Image src={SRC_SovaCharge3} />
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default Assets;
